.button-radius {
  border-radius: 50px;
  min-height: 42px;
}
.bt-color {
  color: #000;
  background-color: #f09503;
  border-color: #f09503;
  font-weight: 500;
}
.primary-btn{
  display: inline-flex;
  min-width: 170px;
  height: 42px;
  color: #172548;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #f09503;
  border-radius: 22px;
  text-decoration: none;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
  transition: all .2s ease-in-out;
}
.primary-btn:hover{
  color: #172548;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.4);
}
.primary-btn:focus{
  box-shadow: 0 0 0 .2rem #f0950340;
}
@media(max-width: 768px){
  .primary-btn{
    height: 38px;
    font-size: 16px;
  }
}
.primary-btn.w-240{
  width: 240px;
}
.primary-btn.ml-15{
  margin-left: 15px;
}
.button-center{
  display: flex;
  justify-content: center;
}
.swal2-styled.swal2-confirm{
  background-color: #f09503 !important;
  color: #000 !important;
}
