/* NavBar CSS */

.pri-nav-wrap {
  display: flex;
  justify-content: center;
  background-color: #212035;
  overflow-x: auto;
  vertical-align: middle;
  align-items: center;
}
.pri-nav-wrap-item {
  padding: 1.5rem;
  color: #ffffff;
  letter-spacing: 0.025rem;
  text-decoration: none;
  text-align: center;
  font-size: 22px;
}
.pri-nav-wrap-item.active {
  position: relative;
  font-weight: 600;
  color: #ffffff;
}
.pri-nav-wrap-item.active:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  height: 0;
  width: 0;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 10px solid;
  border-top: 0;
}
.pri-nav-wrap-item:hover {
  color: #ffffff;
}
/* Media Query CSS */

@media (min-width: 768px) {
  .nav-item-margin {
    margin: 0px 40px;
  }
}@media (max-width: 768px) {
  .nav-font-size {
   font-size: 18px;
  }
}
