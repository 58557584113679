.bg-body {
  background-color: #f6f6f6;
}
.pb-220 {
  padding-bottom: 220px !important;
}
.page-title {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-white {
  color: #fff !important;
}
.mw-bg-white-card {
  background-color: #fff !important;
}
.page-white-card {
  max-width: 1400px;
  margin: 0 auto 0 auto;
  padding: 60px;
}
.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.login-card {
  background: #f0f0f5;
  border-radius: 0.25rem;
}
