.search-card{
    padding: 2rem;
    background-color: #fff;
}
.search-icon {
    position: absolute;
    right: 10px;
    top: 50px;
    pointer-events: none;
}
.hCBnvI{
    background-color: #0269BE !important;
    color: #fff !important;
    font-size: 14px !important;
}
.csv-download {
    background-color: #F09503;
    border-radius: 20px;
    font-size: 13px !important; 
    font-weight: 600;
    margin-bottom: 1rem;
    padding: 10px 45px 10px 20px;
}
.csv-absolute{
    position: absolute;
    right: 15px;
    top: 8px;
}
.hLGqlg{
    border: 1px solid #80808014;
}
.positive {
    background-color: #d62626 !important;
    padding: 5px 10px;
    color: #fff;
    font-weight: 600;
  }
  .negative {
    background-color: #01bb39 !important;
    padding: 5px;
    color: #fff;
    font-weight: 600;
  }
  .invalid {
    background-color: #d1cb27 !important;
    padding: 5px 13px;
    color: #fff;
    font-weight: 600;
  }

  .download-icon{
      position: relative;
  }

@media (max-width:768px) {
    .search-card{
        padding: 1rem;
    }
}