/* React Select - Dropdown styles */
.react-select__menu{
  margin-top: 0 !important;
}
.react-select__menu-list{
  padding: 0 !important;
}
.react-select__menu .react-select__option{
  padding: 8px 20px;
  font-weight: 500;
  cursor: pointer;
}
.react-select__option.react-select__option--is-selected{
  color: #172548;
  background-color: #F09503;
}

.react-select__menu .react-select__option.react-select__option--is-focused{
  color: #172548;
  background-color: rgba(240, 149, 3, 0.2);
}
.react-select__option.react-select__option--is-selected.react-select__option--is-focused{
  color: #172548;
  background-color: #F09503;
}
.react-select__control.react-select__control--is-focused.react-select__control--menu-is-open{
  border-color: none !important;
  box-shadow: 0 0 0 0.2rem #f0950340 !important;
}
.react-select__control{
  height: 50px !important;
  border: 1px solid rgb(190, 188, 188) !important;
  
  box-shadow: none !important;
}
.react-select__input{
  height: 30px !important;
  min-height: 30px !important;
}
.react-select__input:focus{
  box-shadow: none !important;
  outline: none !important; 
}
.react-select__indicator{
  margin: 0px;
}
.react-select__indicator-separator{
  display: none;
}
.react-select__menu.css-i0syzg-menu {
  z-index: 100 !important;
}