.radio-button {
  min-width: 171px;
}
.radio-button label {
  border-radius: 30px;
  padding: 0.5rem;
}
.btn-outline-grey {
  border-color: rgb(190, 188, 188) !important;
}
.btn-outline-primary:active{
  background-color: #0269be !important;
}
.btn-outline-primary:hover {
  color: #ffffff !important;  
  background-color: #0269be;
  border-color: #0269be !important;

}
.btn-outline-primary {
  border-color: rgb(190, 188, 188) !important;
  color: #0d0d0d;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgb(240 240 240 / 25%) !important;
  background-color: #0269be !important;
  border-color: #0269be !important;

}
.btn-outline-primary:focus{
  border-color: #0269be !important;
}