checkmark.in-lilie {
  display: inline-flex;
  flex-direction: row;
  justify-content: right;
  vertical-align: unset;
  align-content: space-between;
}
.in-lilie .check-input {
  margin: 0px;
  width: 24px;
  height: 24px;
}
#checkbox-pr {
  cursor: default;
}

.checkbox-group {
  height: 24px;
  display: block;
  position: relative;
  /* padding-left: 35px;
    margin-bottom: 12px; */
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 35px;
}

/* Hide the browser's default checkbox */
.checkbox-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #bebcbc;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.checkbox-group:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-group input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-group input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-group .checkmark:after {
  left: 4px;
  top: 4px;
  width: 5px;
  height: 5px;
  border: solid #212035;
  border-width: 5px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}