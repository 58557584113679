.db-card {
  background-color: #0269be;
  padding: 1rem;
}
.db-card-title {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
}
.db-body {
  background-color: #ffffff;
}
.db-card-group {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  gap: 27px;
}
.db-card-color {
  background-color: #f0f0f5;
}
.nav-card {
  padding: 1.5rem;
  border-radius: 10px;
}
.db-first-card {
  display: flex;
  background-color: #f0f0f5;
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: 2rem 1.5rem;
}
.font-w {
  font-weight: 700;
}
.db-card-covid {
  color: #fff;
  padding: 0.5rem;
  max-width: 140px;
  text-align: center;
  font-size: 25px;
}
.positive {
  background-color: #d62626;
}
.negative {
  background-color: #01bb39;
}
.invalid {
  background-color: #d1cb27;
}

.db-p {
  font-size: 20px;
}
.db-date{
  align-items: center;
  padding: 0rem 1rem 1rem 1rem;
}
.db-fb{
  align-items: center;
}
@media (max-width: 768px) {
  .db-card-group {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .left-end{
      text-align: end;
  }
  .db-font-size{
      font-size: 16px;
  }
  .db-margin {
    margin-bottom: 12px;
  }
}
