/* Profile Dropdown CSS */

.profile-dropdown.dropdown-toggle::after {
  border: 0 !important;
}
.profile-dropdown.dropdown-toggle {
  border-bottom: 2px solid #ffffff !important;
  border: 0px;
  padding: 0px;
}
.dropdown-item:hover {
  background-color: #f09503 !important;
}

.dropdown-item {
  padding: 0.5rem 1rem !important;
}
.dropdown-menu {
  padding: 0px !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #212529;
}
.droparrow {
  margin-right: -3px;
}