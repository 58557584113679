/* Common CSS property for hole project */

body::-webkit-scrollbar {
  display: none;
}

/* General styles starts here */
.spacer-10{
  width: 100%;
  height: 10px;
}
.spacer-20{
  width: 100%;
  height: 20px;
}
.spacer-30{
  width: 100%;
  height: 30px;
}
.spacer-40{
  width: 100%;
  height: 40px;
}
.spacer-50{
  width: 100%;
  height: 50px;
}
/* General styles ends here */

.h-5 {
  height: 2rem;
}
.text-right {
  text-align: right !important;
}
.negative-bg {
  background-color: #01bb39;
}
.positive-bg {
  background-color: #d62626;
}
.invalid-bg {
  background-color: #D1CB27;
}
.stmt-info {
  min-height: 44px;
  color: #fff;
  font: normal normal 600 18px/40px Montserrat;
  padding: 0 15px !important;
  width: 130px;
}
.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.m-b-0 {
  margin-bottom: 0px;
}
.coivd-bg {
  background-color: #0269be;
  border-radius: 10px;
}
.spe-bg {
  background-color: #f0f0f5;
}
.spe-info {
  min-height: 44px;
  color: #212529; 
  font: normal normal 400 16px/40px Montserrat;
  padding: 0 1rem;
}
.spe-id {
  color: #212529; 
  font: normal normal 400 16px/40px Montserrat;
  padding: 0 1rem;
}

.card{
   border: none !important;
}

@media (max-width: 768px) {
  .card-padding {
  padding: 1rem !important;   
  }
  .ordernow {
    justify-content: center !important;
  }
}
@media (min-width: 768px) {
  .card-padding {
  padding: 4rem;   
  }

}
.forget-color{
  color: #172548;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navbar-nav {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.btn-check:checked+.radio-bt-color {
  background-color: #0269BE !important;
}
.text-end.mt-5 a:hover{
  color: #000 !important;
}
.react-datepicker-popper{
  z-index: 100 !important;
}
.star {
  color: red;
}
.bt:hover {
  box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.16); 
}
@media (max-width: 768px){
  .stmt-info{
    width: auto;
  }
}
.pos-relative {
  position: relative;
}
.pos-calender{
  position: absolute;
  right: 10px;
  top: 12px;
  pointer-events: none;
}
.pos-calender img{
  width: 24px;
}

/* Home page style */
.page-header{
  margin: 0px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}
@media(max-width: 768px){
  .page-header{
    font-size: 26px;
  }
}
@media(max-width: 576px){
  .page-header{
    font-size: 22px;
  }
}
.home-content p{
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
}
@media(max-width: 768px){
  .home-content p{
    font-size: 16px;
  }
}
.section-heading{
  font-size: 20px;
  font-weight: 700;
}
@media(max-width: 576px){
  .section-heading{
    font-size: 18px;
  }
}
.content-blk{
  padding: 40px;
  background: #EDEDEF;
}
@media(max-width: 576px){
  .content-blk{
    padding: 40px 0px;
  }
}

/* Content card styles */
.content-card{
  width: 100%;
  padding: 60px;
  background-color: #fff;
}
@media(max-width: 768px){
  .content-card{
    padding: 40px;
  }
}
@media(max-width: 576px){
  .content-card{
    padding: 20px;
  }
}
.content-inner-card{
  width: 100%;
  padding: 60px;
  background-color: #F0F0F5;
  border-radius: 10px;
}
@media(max-width: 768px){
  .content-inner-card{
    padding: 40px;
  }
}
@media(max-width: 576px){
  .content-inner-card{
    padding: 30px;
  }
  .content-inner-card.form{
    padding: 50px 30px;
  }
}

/* Form styles */
.form-container{
  margin: 0 auto;
  max-width: 420px;
}
.input-group label{
  font-size: 18px;
  font-weight: 500;
}
.input-label{
  margin: 0rem 0rem 0.5rem 0rem;
  font-size: 18px;
  font-weight: 500;
}
@media(max-width: 768px){
  .input-group label{
    font-size: 16px;
  }
  .input-label{
    font-size: 16px;
  }
  .button-space{
    margin-bottom: 10px;
  }
}
.input-group .form-error-msg{
  display: block;
  margin-top: 8px;
}
.d-flex .primary-btn:hover{
color:#172548;
}

/* Top header styles */
.top-header{
  padding: 30px 70px;
  background-color: #0169be;
}
@media(max-width: 992px){
  .top-header{
    padding: 30px 50px;
  }
}
@media(max-width: 768px){
  .top-header{
    padding: 30px;
  }
}
.nhs-logo{
  width: 400px;
}
@media(max-width: 768px){
  .nhs-logo{
    width: 300px;
  }
}
@media(max-width: 576px){
  .nhs-logo{
    width: 280px;
  }
}
@media(max-width: 380px){
  .nhs-logo{
    width: 100%;
  }
}
.top-header h2{
  margin: 0px;
  padding: 20px 0px;
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  text-align: right;
}
@media(max-width: 992px){
  .top-header h2{
    padding: 20px 0px 0px 0px;
    font-size: 26px;
    text-align: left;
  }
}
@media(max-width: 768px){
  .top-header h2{
    font-size: 22px;
  }
}
.device-img{
  width: 200px;
}
@media(max-width: 400px){
  .device-img{
    width: 100%;
  }
}
.card-border {
  border-color: #70707059;
  border-radius: 10px;
}
.font-weight{
  font-weight: 700;
}

.nhs-login.aw-nhs-login-btn {
  outline: none;
  border: 0px;
  background-color: #005eb8;
  min-height: 45px;
  color: #FFFFFF;
  font-weight: 700;
  padding: 8px 10px;  
  border-radius: 4px;
  font-family: "Frutiger W01", Arial, Helvetica, sans-serif;
  font-size: 18px;

}

.nhs-login.aw-nhs-login-btn img {
  margin-right: 10px;
}
@media (max-width:768px) {
  .nhs-login.aw-nhs-login-btn {
    font-size: 14px;
  }
}
.react-datepicker__triangle{
  display: none;
}
.form-control{
  border-radius: 0px;
}
.ordernow{
  display: flex;
  align-items: center;
  justify-content: end;
}

/* Profile page sign in */
.profile-warning{
  margin-bottom: 40px;
  padding: 20px;
  font-size: 20px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #f09503;
  background-color: rgba(240, 149, 3, 0.3);
}

/* Home page NHS button wrap styles */
.home-nhs-wrap{
  padding: 20px;
  border-radius: 8px;
  border: 2px solid #0169be
}
.home-nhs-wrap p{
padding-bottom: 10px;
}

.date-error{
  padding-top: 10px;
    margin: 0;
}