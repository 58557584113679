.input-group input {
  margin: 0px !important;
  border-radius: 1px !important;
}
.input-group input {
  border-radius: 0rem !important;
  border-width: 1px;
  border-color: rgb(190, 188, 188);
  padding: 0.5rem 1rem;
  font-size: 15px;
  line-height: 24px;
  color: rgb(5, 5, 5);
  box-sizing: border-box;
  letter-spacing: 0.8px;
  min-height: 50px;
  border: 1px solid rgb(203, 203, 203);
}
.input-group input:focus {
  border-width: 1px !important;
  border-color: rgb(190, 188, 188) !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  box-shadow: 0 0 0 0.2rem #f0950340;
}
.star {
  color: red;
}
.font-size {
  font-size: 18px;
  font-weight: 500;
}


/* Date picker styles */
.react-datepicker{
  font-family: 'Montserrat', sans-serif !important;
  box-shadow: 0px 3px 12px rgb(0 0 0 / 20%) !important;
}
.datepicker-h {
  min-height: 50px;
  border-radius: 0rem !important;
  box-shadow: 0 0 0 0.25rem rgb(240 240 240 / 25%) !important;
  border-color: rgb(190, 188, 188) !important;
  cursor: pointer;
}
.react-datepicker-popper .react-datepicker__triangle{
  transform: translate(125px, 0px) !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
  transform: translate(140px, 0px) !important;
}
.react-datepicker__navigation-icon--previous::before{
  right: 0px !important;
  top: 12px !important;
}
.react-datepicker__navigation-icon--next::before{
  left: 0px !important;
  top: 12px !important;
}
.react-datepicker__navigation-icon::before{
  border-color: rgba(23, 37, 72, 0.5) !important;
}
.react-datepicker__navigation-icon::before:hover{
  border-color: #172548 !important;
}
.react-datepicker__header__dropdown{
  padding: 8px 0px !important;
}
.react-datepicker__header__dropdown select{
  padding: 0px 8px !important;
  height: 30px !important;
}
.react-datepicker__day{
  border-radius: 50% !important;
}
.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected{
  color: #172548 !important;
  background-color: #f09503 !important;
}
@media (max-width:768px) {
  .font-size {
    font-size: 16px;
  }
}