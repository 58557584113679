.re-card-body {
    display: flex;    
}
.re-card-title {
    font-size: 30px;
    font-weight: 700;
}
.subtitle {
    font-size: 20px;
    font-weight: 600;
}
.re-span {
    background-color: #D62626;  
    padding: 0rem 0.5rem;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.re-sub-card {
    background-color: #fff;
    padding: 2rem;
}
.re-font-w{
    font-weight: normal;
}
.ml-2{
    margin-left: 2rem;
}
.back-arrow {
    font-size: 18px;
    font-weight: 500;
}



@media (max-width:768px) {
    .re-card-body{
        display: block;
    }
    .re-card-title {
        font-size: 22px;
    }
    .re-sub-card {
        padding: 1rem;
    }
    .re-card-subtitle{
        font-size: 18px;
        font-weight: 600;
    }
    .ml-2{
        margin-left: 0rem;
    }
}